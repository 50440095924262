import { useState, useLayoutEffect, useEffect, useRef } from "react";
import { useApi } from "./useApi";
import { getUserManagementApiClient as apiClient } from "../api-helper/apiHelper";
import { Constants, USE_NEW_BACKEND, USE_NEW_BACKEND_KEY } from "../../constants";
import useDidMountEffect from "../../lib/hooks/useDidMountEffect";
import validator from "../../validators/userValidator";
import { toast } from "react-toastify";
import { ModelConfirmation } from "../../components/Modal";
import { useModal } from "../../lib/hooks/useModal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { isV2Api } from "../helper";

export const useAddEditUser = () => {
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [error, setError] = useState<string>();
  const [modalStatus, setModalStatus] = useState<string>("ADD");
  const [{ data: addUserResponse, status: addUserStatus, error: addUserError, statusCode }, addUser, refresh] = useApi(
    apiClient,
    "",
    {
      username: email,
      role: role,
    }
  );

  const [
    { data: updateUserResponse, status: updateUserStatus, error: updateUserError, statusCode: updateUserStatusCode },
    updateUser,
    updateRefresh,
  ] = useApi(apiClient, "", { username: email, updateUser: { role: role } }, "", "PUT");

  const { isShowing, toggle } = useModal();
  const navigate = useNavigate();
  const location = window.location;
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const editDataFetched = useRef<boolean>(false);
  const [searchParams] = useSearchParams();
  const isNewBackend = isV2Api(searchParams.get(USE_NEW_BACKEND_KEY));

  const params = useParams();
  const [{ data: userData, error: userDataError, statusCode: userDataStatus }, getUserData] = useApi(
    apiClient,
    "",
    [encodeURI(params.username)],
    undefined,
    "GET"
  );

  useEffect(() => {
    if (params.username) {
      getUserData(`usersUsernameGet`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  // Handle cancel
  const cancel = () => {
    if (isDirty) {
      toggle();
      navigate(-1);
    } else {
      navigate(isNewBackend ? `/user-management${USE_NEW_BACKEND}` : "/user-management");
    }
  };

  const handleAddUser = async () => {
    // Resetting the error
    setError(null);
    const errors = validator.validate({
      email: email,
      role: role,
    });
    if (errors.length === 0) {
      addUserResponse || addUserError ? refresh() : addUser("usersPost");
      await addUserResponse;
      setIsDirty(false);
    } else {
      errors.forEach((error) => {
        toast.error(error);
      });
    }
  };

  const handleEditUser = async () => {
    const errors = validator.validate({
      email: email,
      role: role,
    });
    if (errors.length === 0) {
      updateUserResponse || updateUserError ? updateRefresh() : updateUser("usersUsernamePut");
      await updateUserResponse;
      setIsDirty(false);
    } else {
      errors.forEach((error) => {
        toast.error(error);
      });
    }
  };

  useLayoutEffect(() => {
    if (userDataStatus === 200) {
      setEmail(userData.email);
      setRole(userData.role);
      setTimeout(() => {
        editDataFetched.current = true;
      }, 500);
    } else if (userDataStatus === 404) {
      toast.error("User does not exist");
    } else {
      toast.error(userDataError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, userDataStatus, userDataError]);

  // identify if edit or add modal
  useLayoutEffect((): void => {
    params.username ? setModalStatus("EDIT") : setModalStatus("ADD");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.username]);

  useLayoutEffect(() => {
    if (addUserResponse !== typeof undefined && (statusCode === 200 || statusCode === 201)) {
      toast.info("User Added");
      navigate(isNewBackend ? `/user-management${USE_NEW_BACKEND}` : "/user-management", { replace: true });
    }

    if (addUserResponse !== typeof undefined && addUserStatus === "error") {
      if (addUserError.includes("UsernameExistsException")) {
        setError("User already exists");
      } else if (addUserError.includes("UserLambdaValidationException")) {
        setError("Domain not associated to PostOffice. Please enter valid domain.");
      } else {
        setError(addUserError);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUserResponse, statusCode, addUserStatus, addUserError]);

  useLayoutEffect(() => {
    if (updateUserResponse !== typeof undefined && updateUserStatusCode === 204) {
      toast.info("User Updated");
      navigate(isNewBackend ? `/user-management${USE_NEW_BACKEND}` : "/user-management", { replace: true });
    }

    if (updateUserResponse !== typeof undefined && updateUserStatusCode === 400) {
      toast.error("User doesn't exists");
    }

    if (updateUserResponse !== typeof undefined && updateUserStatus === "error") {
      toast.error(updateUserError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserResponse, updateUserStatusCode, updateUserStatus, updateUserError]);

  const confirm = (confirmation: string) => {
    toggle();
    if (confirmation === ModelConfirmation.Yes) {
      setIsDirty(false);
      navigate(isNewBackend ? `/user-management${USE_NEW_BACKEND}` : "/user-management");
    }
  };

  useEffect(() => {
    if (!isShowing && isDirty) {
      window.history.pushState(null, null, window.location.pathname + "#/user");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowing]);

  const onBackButtonEvent = (event: PopStateEvent) => {
    event.preventDefault();
    if (isDirty) {
      toggle();
    } else {
      navigate(isNewBackend ? `/user-management${USE_NEW_BACKEND}` : "/user-management");
    }
  };

  const onRoleChange = (selected: any, event: any): void => {
    if (event.action !== "clear") {
      setRole(selected.value);
    } else {
      setRole(null);
    }
  };
  const onEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    if (isDirty) {
      window.history.pushState(null, null, window.location.pathname + "#/user");
      window.addEventListener("popstate", onBackButtonEvent);
      return () => {
        window.removeEventListener("popstate", onBackButtonEvent);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    if (location.hash.includes("#/user")) {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidMountEffect(() => {
    //Do not set dirty, if data is filled from edit api
    if (modalStatus === "EDIT" && !editDataFetched.current) {
      return;
    }
    setIsDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, role, modalStatus]);

  // User Roles options
  const roleOptions = [
    { value: Constants.ADMIN, label: Constants.ADMIN_LABEL },
    { value: Constants.USER, label: Constants.USER_LABEL },
  ];
  return {
    modalStatus,
    email,
    role,
    isShowing,
    roleOptions,
    error,
    onEmailChange,
    onRoleChange,
    handleAddUser,
    handleEditUser,
    toggle,
    cancel,
    confirm,
  };
};
