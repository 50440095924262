import { FC } from "react";
import { Navigate, Route, Routes, BrowserRouter } from "react-router-dom";
import Manager from "./pages/Manager";
import UserManagement from "./pages/UserManagement";
import Login from "./pages/Login";
import PageNotFound from "./pages/PageNotFound";
import Header from "./components/layout/header";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import AddEditDevice from "./pages/AddEditDevice";
import AddEditUser from "./pages/AddEditUser";
import Authenticated from "./components/authenticated";

const RedirectPath = () => <Navigate replace to="/manager" />;

const App: FC = () => (
  <BrowserRouter>
    <ToastContainer
      autoClose={3000}
      hideProgressBar={false}
      draggable={false}
      limit={2}
      role="alert"
      transition={Flip}
    />
    <Header />
    <Routes>
      <Route path="/" element={<RedirectPath />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/manager"
        element={
          <Authenticated>
            <Manager />
          </Authenticated>
        }
      />
      <Route
        path="/user-management"
        element={
          <Authenticated>
            <UserManagement />
          </Authenticated>
        }
      />
      <Route path="/device/">
        <Route
          path=""
          element={
            <Authenticated>
              <AddEditDevice />
            </Authenticated>
          }
        />
        <Route
          path=":deviceID"
          element={
            <Authenticated>
              <AddEditDevice />
            </Authenticated>
          }
        />
      </Route>
      <Route path="/user">
        <Route
          path=""
          element={
            <Authenticated>
              <AddEditUser />
            </Authenticated>
          }
        />
        <Route
          path=":username"
          element={
            <Authenticated>
              <AddEditUser />
            </Authenticated>
          }
        />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  </BrowserRouter>
);

export default App;
