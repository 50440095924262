import { Auth } from "aws-amplify";
import React, { FC } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import { USE_NEW_BACKEND, USE_NEW_BACKEND_KEY } from "../../constants";
import { isV2Api } from "../../lib/helper";
import useUserStatus from "../../lib/hooks/useUserStatus";

const Header: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleLogout = async () => {
    await Auth.signOut();
    navigate("/login");
  };
  const [searchParams] = useSearchParams();
  const isNewBackend = isV2Api(searchParams.get(USE_NEW_BACKEND_KEY));
  const userStatus = useUserStatus();
  const isLoggedIn = null !== userStatus;

  return (
    <nav className="bg-white border-b border-gray-200">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex items-center flex-shrink-0">
              <img className="block w-auto h-8" src={Logo} alt="Post Office" />
            </div>
            <div
              className="cursor-pointer sm:-my-px sm:ml-6 sm:flex sm:space-x-8"
              onClick={() => navigate(isNewBackend ? `/manager${USE_NEW_BACKEND}` : "/manager")}
            >
              <h3
                className={`inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 border-b-2  ${
                  location.pathname === "/manager" ? "border-pol-red border-b-2" : "border-gray-200 border-b"
                }`}
              >
                Device Management
              </h3>
            </div>
            <div
              className="cursor-pointer sm:-my-px sm:ml-6 sm:flex sm:space-x-8"
              onClick={() => navigate(isNewBackend ? `/user-management${USE_NEW_BACKEND}` : "/user-management")}
            >
              <h3
                className={`inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900   ${
                  location.pathname === "/user-management" ? "border-pol-red border-b-2" : "border-gray-200 border-b"
                }`}
              >
                User Management
              </h3>
            </div>
          </div>
          <div className="flex items-center ml-6">
            <div className="relative ml-3">
              {isLoggedIn && (
                <button
                  data-test="auth-flow-btn"
                  onClick={handleLogout}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  Log Out
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
