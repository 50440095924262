import React, { FC, useState, useLayoutEffect, useRef, useContext } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { PencilAltOutline, UserAddOutline, Eye } from "heroicons-react";
import { Terminal } from "../lib/models";
import { SearchBox } from "./table/searchBox";
import { ActionType } from "../context/reducer";
import { AppContext } from "../context/store";
import Otp from "./table/Otp";
import Modal, { ModelConfirmation } from "./Modal";
import { useModal } from "../lib/hooks/useModal";
import TerminalViewModal from "./TerminalViewModal";
import { Constants } from "../constants";
import { useExportDevices } from "../lib/hooks/useExportDevices";
import { CSVLink } from "react-csv";

export interface TerminalTableProps {
  rows: Terminal[];
  searchCallback?: (search: string) => void;
  addEditDevicecallback: (deviceID: string) => void;
}

const TerminalTableComp: FC<TerminalTableProps> = ({ rows, searchCallback, addEditDevicecallback }): JSX.Element => {
  const searchRef = useRef<HTMLInputElement>(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const { state, dispatch } = useContext(AppContext);
  const { isShowing, toggle } = useModal();
  const [isViewPage, setViewPage] = useState(false);
  const [device, setDevice] = useState<Terminal>();
  const [isExportModal, setExportModal] = useState(false);
  const [exportFileName, setExportFileName] = useState("");
  const csvExportRef = useRef(null);

  const { deviceHeaders, exportedDevices } = useExportDevices();

  // sets row data into the datatable when data changes
  useLayoutEffect(() => {
    setFilteredItems(rows);
  }, [rows]);

  useLayoutEffect(() => {
    if (exportFileName) {
      csvExportRef.current.link.click();
    }
  }, [exportFileName]);

  const handleKeyUp = (e: { key: string }) => {
    if (
      e.key === "Enter" &&
      searchRef.current.value !== "undefined" &&
      (searchRef.current.value.length > 2 || searchRef.current.value.length === 0)
    ) {
      searchCallback(searchRef.current.value);
    }
  };
  const handleOnBlur = (e) => {
    if (searchRef.current.value.length > 2 || searchRef.current.value.length === 0) {
      searchCallback(searchRef.current.value);
    }
  };

  const onReset = () => {
    searchRef.current.value = "";
    searchCallback("");
  };

  // Opens device page with current selected device
  const handleOnEditClick = (row: Terminal) => {
    dispatch({ type: ActionType.SET_DEVICE, payload: row });
    addEditDevicecallback(row.id);
  };

  // Opens OTP generation pop-up
  const handleOTPClick = (row: Terminal) => {
    setDevice(row);
    toggle();
  };

  //Opens View_page pop-up
  const handleViewPopup = (row: Terminal) => {
    setDevice(row);
    setViewPage(true);
  };

  // Edit button in datatable
  const editButton = (row: Terminal): JSX.Element => {
    return (
      <button
        type="button"
        className="inline-flex items-center btn btn-clear mr-2"
        onClick={(): void => handleOnEditClick(row)}
      >
        <PencilAltOutline size={18} className="mr-2" /> Edit
      </button>
    );
  };

  const generateOtpButton = (row: Terminal): JSX.Element => {
    return (
      <button
        type="button"
        className="inline-flex items-center btn btn-clear"
        onClick={(): void => handleOTPClick(row)}
      >
        Generate OTP
      </button>
    );
  };

  // View Button in dataTable
  const viewButton = (row: Terminal): JSX.Element => {
    return (
      <button
        type="button"
        className="inline-flex items-center btn btn-clear mr-2"
        onClick={(): void => handleViewPopup(row)}
      >
        <Eye size={18} className="mr-2" /> View
      </button>
    );
  };

  const createActions = (row: Terminal): JSX.Element => {
    return (
      <>
        {viewButton(row)}
        {state.userRole === Constants.ADMIN && editButton(row)}
        {generateOtpButton(row)}
      </>
    );
  };

  const exportDataConfirm = (confirmation: string) => {
    setExportModal(false);
    if (confirmation === ModelConfirmation.Yes) {
      setExportFileName(`DeviceExport_${moment(new Date()).format("DDMMYYHHmmss")}.csv`);
    }
  };

  // memorized subComponent for data table which created data table header including search
  const subHeaderComponentMemo = React.useMemo((): JSX.Element => {
    return (
      <div className="flex w-full py-4">
        <div className="flex-1 min-w-0">
          <div className="inline-flex">
            <button
              type="button"
              data-cy="add-modal-button"
              className="inline-flex items-center btn btn-clear"
              onClick={(): void => handleOnEditClick({} as Terminal)}
            >
              <UserAddOutline size={18} className="mr-2" /> Add Device
            </button>
            <button
              type="button"
              data-cy="export-modal-button"
              className="inline-flex items-center btn btn-clear ml-4"
              onClick={(): void => setExportModal(true)}
            >
              Export CSV
            </button>
            <CSVLink
              ref={csvExportRef}
              data-testid="csvLink"
              headers={deviceHeaders}
              data={exportedDevices(state.devices)}
              filename={exportFileName}
              target="_blank"
            ></CSVLink>
          </div>
        </div>
        <div className="inline-flex items-end w-1/4">
          <SearchBox ref={searchRef} onKeyUp={handleKeyUp} handleOnBlur={handleOnBlur} onReset={onReset} />
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.devices, exportFileName]);

  // handles checking for no branch and changing row colour
  const conditionalRowStyles = [
    {
      when: (row) => row.branch_id === "",
      style: {
        backgroundColor: "seashell",
        color: "black",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "pink",
        },
      },
    },
  ];

  const hideViewModel = () => {
    setViewPage(false);
  };

  // Creates datatable columns, includes formatting of date and sortable bool
  const newCols = React.useMemo(
    () => [
      {
        name: "Device ID",
        selector: "id",
        sortable: true,
        maxWidth: "185px",
      },
      {
        name: "Device Name",
        selector: "name",
        sortable: true,
        maxWidth: "185px",
      },
      {
        name: "Branch Id",
        selector: "branch_id",
        sortable: true,
        maxWidth: "185px",
      },
      {
        name: "Branch Name",
        selector: "branch_name",
        sortable: true,
        maxWidth: "185px",
      },
      {
        name: "Device Type",
        selector: "type",
        sortable: true,
        maxWidth: "185px",
      },
      {
        name: "Last Modified",
        selector: "modified_at",
        cell: (row: Terminal) => {
          return row.modified_at && row.modified_at !== "0001-01-01T00:00:00Z"
            ? moment(row.modified_at).format("MMM DD, YYYY HH:mm:ss")
            : "N/A";
        },
        sortable: true,
        maxWidth: "185px",
      },
      {
        name: "Actions",
        button: true,
        cell: (row: Terminal) => createActions(row),
        right: true,
        minWidth: "400px",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.userRole]
  );

  const noDataComponent = React.useMemo((): JSX.Element => {
    return <div className="no-results">No results found. Please note this search is case-sensitive</div>;
  }, []);

  return (
    <div data-testid="dataTable">
      <DataTable
        className="data-table"
        columns={newCols}
        data={filteredItems}
        pagination
        highlightOnHover
        noDataComponent={noDataComponent}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        conditionalRowStyles={conditionalRowStyles}
        defaultSortFieldId="created_at"
        defaultSortAsc
      />

      {isShowing && (
        <Modal
          isShowing={isShowing}
          hide={toggle}
          modalHeader={"Generate OTP for " + device.id}
          closeable={true}
          fullPage={true}
          modalBody={<Otp device={device} />}
        />
      )}

      {isViewPage && (
        <Modal
          isShowing={isViewPage}
          hide={hideViewModel}
          modalHeader={"Device Details"}
          closeable={true}
          fullPage={true}
          modalBody={<TerminalViewModal row={device} />}
        />
      )}

      {isExportModal && (
        <Modal
          isShowing={isExportModal}
          hide={null}
          confirmation={exportDataConfirm}
          modalBody={<div>Do you want to save devices CSV file on your machine?</div>}
        />
      )}
    </div>
  );
};

export default TerminalTableComp;
