import React, { useEffect, useRef } from "react";

const useDidMountEffect = (func: any, deps: any) => {
  const didMount: React.MutableRefObject<boolean> = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      func();
    } else didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useDidMountEffect;
