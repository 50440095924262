import {
  SIX_DIGIT_REGEXP,
  BRANCH_ID_REGEXP,
  Constants,
  INPUT_BOX_MIN_CHARACTERS,
  DEVICE_NAME_MAX_CHARACTERS,
  INPUT_BOX_MAX_CHARACTERS,
  NOTES_MAX_CHARACTERS,
} from "../constants";
interface Validator {
  validate: (data: Record<string, unknown>) => string[];
}

const NODE_ID_REGEXP = new RegExp(/^[3][2-9]$|^[4-5][0-9]$|^[6][0-3]$/i);

const deviceValidator = (data: Record<string, unknown>): string[] => {
  const errors: string[] = [];

  if (String(data["id"]).indexOf(" ") !== -1) {
    errors.push(Constants.DEVICE_ID_SPACE_CHAR_ERROR);
  }

  if (data["id"] === undefined || String(data["id"]).length < INPUT_BOX_MIN_CHARACTERS) {
    errors.push(Constants.DEVICE_ID_MIN_CHAR_ERROR);
  }

  if (String(data["id"]).length > INPUT_BOX_MAX_CHARACTERS) {
    errors.push(Constants.DEVICE_ID_MAX_CHAR_ERROR);
  }

  if (data["name"] === undefined || String(data["name"]).length < INPUT_BOX_MIN_CHARACTERS) {
    errors.push(Constants.DEVICE_NAME_MIN_CHAR_ERROR);
  }

  if (String(data["name"]).length > DEVICE_NAME_MAX_CHARACTERS) {
    errors.push(Constants.DEVICE_NAME_MAX_CHAR_ERROR);
  }

  if (data["type"] === undefined || String(data["type"]).trim() === "") {
    errors.push(Constants.DEVICE_TYPE_ERROR);
  }

  if (
    data["branch_id"] === undefined ||
    !(BRANCH_ID_REGEXP.test(String(data["branch_id"])) || SIX_DIGIT_REGEXP.test(String(data["branch_id"])))
  ) {
    errors.push(Constants.BRANCH_ID_ERROR);
  }

  if (data["node_id"] !== undefined && String(data["node_id"]).length > 2) {
    errors.push(Constants.NODE_ID_MAX_CHAR_ERROR);
  }

  if (!NODE_ID_REGEXP.test(String(data["node_id"]))) {
    errors.push(Constants.NODE_ID_ERROR);
  }

  if (String(data["notes"]).length > NOTES_MAX_CHARACTERS) {
    errors.push(Constants.NOTES_MAX_CHAR_ERROR);
  }

  return errors;
};

const buildValidator = (entityValidator: (data: Record<string, unknown>) => string[]): Validator => {
  return Object.freeze({ validate: entityValidator });
};

export default buildValidator(deviceValidator);
