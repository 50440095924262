import { USE_NEW_BACKEND } from "../constants";

// Returns encoded device path
export const editDevicePath = (id: string, isV2Api: boolean) => {
  return isV2Api ? `/device/${encodeURIComponent(id)}${USE_NEW_BACKEND}` : `/device/${encodeURIComponent(id)}`;
};

// Returns device path
export const addDevicePath = (isV2Api: boolean) => {
  return isV2Api ? `/device${USE_NEW_BACKEND}` : `/device/`;
};

export const isV2Api = (param: string) => {
  return param === "true"
};
