import React, { forwardRef, ForwardRefExoticComponent, LegacyRef, RefAttributes } from "react";
import { Search } from "heroicons-react";
import "../../assets/css/components/terminal-search-box.css";
import { INPUT_BOX_MAX_CHARACTERS } from "../../constants";

interface SearchBoxProps {
  handleOnBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  onReset?: () => void;
}

export const SearchBox: ForwardRefExoticComponent<SearchBoxProps & RefAttributes<HTMLInputElement>> = forwardRef<
  HTMLInputElement,
  SearchBoxProps
>(({ handleOnBlur, onKeyUp, onReset }, ref: LegacyRef<HTMLInputElement>): JSX.Element => {
  return (
    <div className="flex-1 m-w-0">
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="search-wrapper relative mt-0 rounded-md ring-1 ring-gray-300">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <Search size={18} className="mr-2" />
        </div>
        <input
          data-testid="searchInput"
          type="text"
          id="search"
          maxLength={INPUT_BOX_MAX_CHARACTERS}
          placeholder="Please enter atleast 3 characters..."
          onBlur={handleOnBlur}
          onKeyUp={onKeyUp}
          ref={ref}
          className="search-box block w-full py-2 pl-10 pr-3 border-gray-600 rounded-md focus:ring-pol-red-dark focus:border-pol-red-dark sm:text-sm"
        />
        <button className="reset-search-button" data-testid="search-reset" onClick={onReset} type="reset">
          &times;
        </button>
      </div>
    </div>
  );
});
