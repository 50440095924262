//@ts-nocheck

import { useLayoutEffect } from "react";

// Disables scrolling on modals when they are mounted.
// https://usehooks.com/useLockBodyScroll/

function useLockBodyScroll(toggle = true) {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    document.body.style.overflow = toggle ? "hidden" : originalStyle;
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle);
  }, [toggle]); // Empty array ensures effect is only run on mount and unmount
}

export { useLockBodyScroll };
