import { Configuration } from "../../openapi/device-api/configuration";
import { DeviceManagementApi, UserManagementApi } from "../../openapi/device-api/api";
import { DefaultApi as BranchSearchApi } from "../../openapi/branch-api/api";
import { Auth } from "aws-amplify";
import axios from "axios";

import apiConfig from "../../config";

const axiosInstance = axios.create();

// Gets JWT token from amplify and assigns it to OpenAPI Configuration Object
async function getAccessToken(url: string) {
  const idToken = (await Auth.currentSession()).getIdToken();
  const config = new Configuration();
  config.accessToken = idToken.getJwtToken();
  config.basePath = url;
  return config;
}

export const getUserManagementApiClient = async () => {
  return new UserManagementApi(await getAccessToken(apiConfig.baseApi), undefined, axiosInstance);
};

export const getDeviceManagementApiClient = async () => {
  return new DeviceManagementApi(await getAccessToken(apiConfig.baseApi), undefined, axiosInstance);
};

export const getBranchSearchApiClient = async () => {
  return new BranchSearchApi(await getAccessToken(apiConfig.refDataApi), undefined, axiosInstance);
};
