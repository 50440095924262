export enum Constants {
  ADMIN = "admin",
  ADMIN_LABEL = "Admin",
  USER = "user",
  USER_LABEL = "User",
  Add_USER = "Add User",
  EDIT_USER = "Edit User",
  CREATE_USER = "Create User",
  UPDATE_USER = "Update User",

  DEVICE_ID_MAX_CHAR_ERROR = "Max. Char limit for Device ID is 50.",
  DEVICE_ID_MIN_CHAR_ERROR = "Device ID is a mandatory field. Please enter atleast 3 character.",
  DEVICE_ID_SPACE_CHAR_ERROR = "Device ID should not contain any spaces",
  DEVICE_ID_ALREADY_EXISTS_ERROR = "Device ID exists. Please try with new Device ID",
  DEVICE_NAME_MAX_CHAR_ERROR = "Max Char limit for Device Name is 50.",
  DEVICE_NAME_MIN_CHAR_ERROR = "Device Name is a mandatory field. Please enter atleast 3 characters.",
  BRANCH_ID_ERROR = "Branch ID is a mandatory field. Please enter 6 to 7 digits or 6 digits and X.",
  BRANCH_ID_NOT_FOUND_ERROR = "No Active branch/matching branch code. Branch ID can be 6 to 7 digits or 6 digits and X",
  BRANCH_ID_CHANGE_ERROR = "You have made changes to Branch ID. Please validate it by clicking Find Branch",
  DEVICE_TYPE_ERROR = "Please select the Device type.",
  NODE_ID_MAX_CHAR_ERROR = "Max Char limit for Node Id is 2.",
  NODE_ID_ERROR = "Node Id is a mandatory field. Please choose a node between 32 and 63.",
  NODE_ID_ALREADY_USED_ERROR = "Node ID entered is allocated to another device in the current branch. Please try another Node ID",
  NOTES_MAX_CHAR_ERROR = "Max Char limit for Notes is 256.",

  DEVICE_REGISTERED_SUCCESSFULLY = "Device Added - Click Generate OTP to get your OTP",
  DEVICE_UPDATED_SUCCESSFULLY = "Device Updated",
}
export const SIX_DIGIT_REGEXP = new RegExp(/^\d{6}$/i);
export const BRANCH_ID_REGEXP = new RegExp(/^\d{6}[\dX]?$/);
export const INPUT_BOX_MAX_CHARACTERS = 50;
export const INPUT_BOX_MIN_CHARACTERS = 3;
export const DEVICE_NAME_MAX_CHARACTERS = 50;
export const NOTES_MAX_CHARACTERS = 256;
export const USE_NEW_BACKEND_KEY = "useNewBackend";
export const USE_NEW_BACKEND = `?${USE_NEW_BACKEND_KEY}=true`;
export const DEVICE_TYPE_OPTIONS = [
  { value: "payzone", label: "payzone" },
  { value: "counter", label: "counter" },
];
export enum BRANCH_FINDER_ERRORS {
  ERROR_901 = "Failed to retrieve branch details",
  ERROR_900 = "Failed to retrieve branch details",
}
