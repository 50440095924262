import { useContext, useEffect, useState } from "react";
import { useApi } from "./useApi";
import { AppContext } from "../../context/store";
import { ActionType } from "../../context/reducer";
import { getUserManagementApiClient as apiClient } from "../../lib/api-helper/apiHelper";
import { Auth } from "aws-amplify";

export const useUsers = () => {
  const { state, dispatch } = useContext(AppContext);
  const [searchText, setSearchText] = useState<string>("");
  const [offset, setOffset] = useState<string>("");
  const [limit, setLimit] = useState<number>(60);
  const [page, setPage] = useState<number>();
  const [currentState, setCurrentState] = useState<string>("");

  const [{ data: usersData }, getUsers, refresh] = useApi(
    apiClient,
    "",
    [limit, offset, searchText],
    currentState,
    "GET"
  );

  useEffect(() => {
    async function fetchCurrentUserRole() {
      const idToken = (await Auth.currentSession()).getIdToken();
      dispatch({ type: ActionType.SET_USER_ROLE, payload: idToken.payload["custom:role"] });
    }
    fetchCurrentUserRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.users !== typeof undefined) {
      setCurrentState(state.users);
    }

    if (state.nextPage.page !== undefined) {
      setOffset(state.nextPage.page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.users, state.nextPage]);

  useEffect(() => {
    async function loadData() {
      const usersResponse = await usersData;
      dispatch({ type: ActionType.TOTAL_ROWS, payload: usersResponse.total });
      if (usersResponse.offset && state.nextPage[page] === undefined) {
        setOffset(usersResponse.offset);
        dispatch({ type: ActionType.NEXT_PAGE, payload: usersResponse.offset });
      }
      if (state.users.length !== 0) {
        const currentUsers = state.users.users;
        const nextUsers = usersResponse.users;
        usersResponse.users = currentUsers.concat(nextUsers);
      }
      dispatch({ type: ActionType.SET_USERS, payload: usersResponse });
    }

    usersData && loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersData]);

  useEffect(() => {
    async function loadNextData() {
      updatePage(limit);
    }
    offset && loadNextData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  useEffect(() => {
    setOffset("");
    dispatch({ type: ActionType.SET_USERS, payload: [] });
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const refreshUsers = () => {
    !usersData ? getUsers("usersGet") : refresh();
  };

  const retrieveUsers = () => {
    getUsers("usersGet");
  };

  const newPage = (page, limit) => {
    setPage(page);
    let currentPage = state.nextPage[page - 1].page;
    setOffset(currentPage);
    setLimit(limit);

    usersData ? refresh() : getUsers("usersGet");
  };

  const updatePage = (limit) => {
    setLimit(limit);
    usersData ? refresh() : getUsers("usersGet");
  };

  const searchUser = (searchString: string) => {
    setSearchText(searchString);
  };

  return {
    refreshUsers,
    retrieveUsers,
    newPage,
    updatePage,
    searchUser,
  };
};
