export const useErrorMessage = () => {
  const errorFormattor = (message) => {
    let arr = [];
    // eslint-disable-next-line array-callback-return
    message.split("\n").map(function (str, i) {
      let obj = {};
      let strParts = str.split(":");
      if (strParts[0] && strParts[1]) {
        arr.push((obj[strParts[0].replace(/\s+/g, "")] = strParts[1].trim()));
      }
    }, {});

    const envOptions = {
      "'RegisterDeviceRequest.ID' Error": "Device ID Required",
      "'RegisterDeviceRequest.Name' Error": "Device Name Required",
      "'RegisterDeviceRequest.Type' Error": "Device Type Required",
      "'RegisterDeviceRequest.NodeID' Error": "Device Type Required",
      default: "Unknown Error occured, please try again or contact an administrator",
    };
    const errSelector = (key) => envOptions[key] || envOptions.default;
    const formattedMessage = arr.map((item) => {
      return errSelector(item);
    });

    return formattedMessage;
  };

  return {
    errorFormattor,
  };
};
