interface Validator {
  validate: (data: Record<string, unknown>) => string[];
}
interface Field {
  name: string;
  title: string;
}

const EMAIL_REGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const requiredUserFields: Field[] = [
  { name: "email", title: "User Email Address" },
  { name: "role", title: "User Role" },
];

const userValidator = (data: Record<string, unknown>): string[] => {
  const errors: string[] = [];
  const missingFields = requiredUserFields.reduce((memo, field): string[] => {
    if (data[field.name] === undefined || String(data[field.name]).trim() === "") {
      return memo.concat(field.title);
    }
    return memo;
  }, []);

  if (missingFields.length !== 0) {
    errors.push(`Missing required fields: ${missingFields.join(", ")}`);
    return errors;
  }

  if (!EMAIL_REGEX.test(String(data["email"]))) {
    errors.push("Invalid Email Address");
  }

  return errors;
};

const buildValidator = (entityValidator: (data: Record<string, unknown>) => string[]): Validator => {
  return Object.freeze({ validate: entityValidator });
};

export default buildValidator(userValidator);
