import { useState, useLayoutEffect, useEffect, useRef } from "react";
import { useApi } from "./useApi";
import useDidMountEffect from "./useDidMountEffect";
import { useErrorMessage } from "./useErrorMessage";
import validator from "../../validators/deviceValidator";
import { toast } from "react-toastify";
import { ModelConfirmation } from "../../components/Modal";
import { useModal } from "./useModal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import {
  getDeviceManagementApiClient as apiClient,
  getBranchSearchApiClient as branchapiClient,
} from "../api-helper/apiHelper";
import { TerminalAddDataProps } from "../../components/TerminalAddModal";
import {
  Constants,
  DEVICE_NAME_MAX_CHARACTERS,
  INPUT_BOX_MAX_CHARACTERS,
  NOTES_MAX_CHARACTERS,
  BRANCH_FINDER_ERRORS,
  USE_NEW_BACKEND_KEY,
  USE_NEW_BACKEND,
} from "../../constants";
import { isV2Api } from "../helper";

export const useAddTerminal = ({ pageType }) => {
  const [branchId, setBranchId] = useState<string>("");
  const [branchValid, setBranchValid] = useState<boolean>(true);
  const [branchName, setBranchName] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [terminalName, setTerminalName] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [deviceId, setDeviceId] = useState<string>("");
  const [nodeId, setNodeId] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const { errorFormattor } = useErrorMessage();
  const [branchOrgUnitCode, setBranchOrgUnitCode] = useState<string | undefined>("");
  const [branchOrgUnitCodeVersion] = useState<string | undefined>("");
  const [branchAddress, setBranchAddress] = useState<string>("");
  const [branchPostcode, setBranchPostcode] = useState<string>("");
  const { isShowing, toggle } = useModal();
  const navigate = useNavigate();
  const location = window.location;
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const editDataFetched = useRef<boolean>(false);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const isNewBackend = isV2Api(searchParams.get(USE_NEW_BACKEND_KEY));

  const [
    { data: deviceApiResponse, status: deviceApiStatus, error: deviceApiError, statusCode: deviceApiStatusCode },
    fetchDevice,
    refreshDevice,
  ] = useApi(apiClient, "", {
    branch_address: branchAddress,
    branch_id: branchId,
    branch_name: branchName,
    branch_org_unit_code_version: branchOrgUnitCodeVersion,
    branch_org_unit_code: branchOrgUnitCode,
    branch_postcode: branchPostcode,
    id: deviceId,
    name: terminalName,
    node_id: nodeId,
    type: type,
    notes: notes,
  });

  const [
    { data: deviceDetailApiResponse, error: deviceDetailApiError, statusCode: deviceDetailApiStatus },
    getDeviceList,
  ] = useApi(apiClient, "", [params.deviceID], undefined, "GET");
  const [{ data: branchData, error: branchError, statusCode: status }, getBranchData, branchRefresh] = useApi(
    branchapiClient,
    "",
    ["Device_Manager", branchId],
    undefined,
    "GET"
  );

  // Handle device type selectbox changes
  const onTypeChange = (selected: any, event: any): void => {
    if (event.action !== "clear") {
      setType(selected.value);
      removeValidationErrors([Constants.DEVICE_TYPE_ERROR]);
    } else {
      setType(null);
    }
  };

  useEffect(() => {
    if (params.deviceID) {
      getDeviceList(isNewBackend ? "v2DeviceIdGet" : "deviceIdGet");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const onBranchIdChange = (branchId: string) => {
    setBranchId(branchId.toUpperCase());
    setBranchValid(false);
  };

  const handleMaxCharValidation = (inputValue: string, errors: string[], maxLength: number) => {
    if (inputValue.length > maxLength && !validationErrors.includes(errors[0])) {
      validationErrors.push(errors[0]);
      setValidationErrors(validationErrors);
    } else if (inputValue.length <= maxLength && validationErrors.includes(errors[0])) {
      removeValidationErrors(errors);
    }
  };

  // Handle input for device name change
  const onTerminalNameChange = (event: any) => {
    const deviceNameValue = event.target.value as string;
    setTerminalName(deviceNameValue);
    handleMaxCharValidation(
      deviceNameValue,
      [Constants.DEVICE_NAME_MAX_CHAR_ERROR, Constants.DEVICE_NAME_MIN_CHAR_ERROR],
      DEVICE_NAME_MAX_CHARACTERS
    );
  };

  const onNotesChange = (event: any) => {
    const notesValue = event.target.value as string;
    setNotes(notesValue);
    handleMaxCharValidation(notesValue, [Constants.NOTES_MAX_CHAR_ERROR], NOTES_MAX_CHARACTERS);
  };

  // Handle input for deviceId change
  const onDeviceIdChange = (event: any) => {
    const deviceIdValue = event.target.value as string;

    setDeviceId(deviceIdValue);
    handleMaxCharValidation(
      deviceIdValue,
      [Constants.DEVICE_ID_MAX_CHAR_ERROR, Constants.DEVICE_ID_MIN_CHAR_ERROR],
      INPUT_BOX_MAX_CHARACTERS
    );
  };

  // Handle input for nodeId change
  const onNodeIdChange = (event: any) => {
    const nodeIdValue = event.target.value as string;
    setNodeId(nodeIdValue);
    handleMaxCharValidation(nodeIdValue, [Constants.NODE_ID_MAX_CHAR_ERROR, Constants.NODE_ID_ERROR], 2);
  };

  // Handle cancel
  const cancel = () => {
    if (isDirty) {
      toggle();
      navigate(-1);
    } else {
      navigate(isNewBackend ? `/manager${USE_NEW_BACKEND}` : "/");
    }
  };

  // Submit device - branch optional
  const handleAddDevice = async () => {
    setValidationErrors([]);
    const errors = validator.validate({
      branch_address: branchAddress,
      branch_id: branchId,
      branch_name: branchName,
      branch_org_unit_code_version: branchOrgUnitCodeVersion,
      branch_org_unit_code: branchOrgUnitCode,
      branch_postcode: branchPostcode,
      id: deviceId,
      name: terminalName,
      node_id: nodeId,
      type: type,
      notes: notes,
    });

    if (errors.length === 0) {
      if (!branchValid) {
        setValidationErrors([Constants.BRANCH_ID_CHANGE_ERROR]);
        return;
      }
      deviceApiResponse || deviceApiError ? refreshDevice() : fetchDevice(isNewBackend ? "v2DevicePost" : "devicePost");
      await deviceApiResponse;
    } else {
      setValidationErrors(errors);
    }
  };

  // Submit branch put to device
  const handleEditDevice = async () => {
    setValidationErrors([]);
    const errors = validator.validate({
      branch_address: branchAddress,
      branch_id: branchId,
      branch_name: branchName,
      branch_org_unit_code_version: branchOrgUnitCodeVersion,
      branch_org_unit_code: branchOrgUnitCode,
      branch_postcode: branchPostcode,
      id: deviceId,
      name: terminalName,
      node_id: nodeId,
      type: type,
      notes: notes,
    });

    if (errors.length === 0) {
      if (!branchValid) {
        setValidationErrors([Constants.BRANCH_ID_CHANGE_ERROR]);
        return;
      }
      deviceApiResponse || deviceApiError ? refreshDevice() : fetchDevice(isNewBackend ? "v2DevicePut" : "devicePut");
      await deviceApiResponse;
    } else {
      setValidationErrors(errors);
    }
  };

  useLayoutEffect(() => {
    if (deviceApiResponse !== typeof undefined && deviceApiStatusCode === 201) {
      setIsDirty(false);
      setTimeout(() => {
        navigate(isNewBackend ? `/manager${USE_NEW_BACKEND}` : "/", { replace: true });
      }, 1000);
      toast.info(Constants.DEVICE_REGISTERED_SUCCESSFULLY);
    } else if (deviceApiResponse !== typeof undefined && deviceApiStatusCode === 200) {
      toast.success(Constants.DEVICE_UPDATED_SUCCESSFULLY, { theme: "light" });
      setIsDirty(false);
      setTimeout(() => {
        navigate(isNewBackend ? `/manager${USE_NEW_BACKEND}` : "/", { replace: true });
      }, 1000);
    } else if (deviceApiStatusCode === 409 && deviceApiStatus === "error") {
      setValidationErrors([Constants.NODE_ID_ALREADY_USED_ERROR]);
      return;
    } else if (deviceApiError && deviceApiStatus === "error") {
      if (deviceApiError.includes("UsernameExistsException")) {
        setValidationErrors([Constants.DEVICE_ID_ALREADY_EXISTS_ERROR]);
        toast.error(Constants.DEVICE_ID_ALREADY_EXISTS_ERROR);
      } else {
        setValidationErrors([deviceApiError as string]);
        errorFormattor(deviceApiError).forEach((i) => {
          toast.error(i);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceApiResponse, deviceApiError, deviceApiStatus, deviceApiStatusCode]);

  useLayoutEffect(() => {
    if (deviceDetailApiStatus === 200) {
      setBranchAddress(deviceDetailApiResponse.branch_address);
      setDeviceId(deviceDetailApiResponse.id);
      setTerminalName(deviceDetailApiResponse.name);
      setType(deviceDetailApiResponse.type);
      setNodeId(deviceDetailApiResponse.node_id);
      setBranchId(deviceDetailApiResponse.branch_id);
      setBranchName(deviceDetailApiResponse.branch_name);
      setBranchAddress(deviceDetailApiResponse.branch_address);
      setBranchPostcode(deviceDetailApiResponse.branch_postcode);
      setBranchOrgUnitCode("" + deviceDetailApiResponse.branch_org_unit_code);
      setNotes(deviceDetailApiResponse.notes);
      setTimeout(() => {
        editDataFetched.current = true;
      }, 500);
    } else {
      toast.error(deviceDetailApiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceDetailApiResponse, deviceDetailApiStatus, deviceDetailApiError]);

  useLayoutEffect(() => {
    if (status === 200) {
      removeValidationErrors([
        Constants.BRANCH_ID_CHANGE_ERROR,
        Constants.BRANCH_ID_ERROR,
        Constants.BRANCH_ID_NOT_FOUND_ERROR,
      ]);
      if (branchData.Data && branchData.Data.length > 0) {
        let data = branchData.Data[0];
        setBranchId(data.BranchId);
        setBranchName(data.BranchName);
        setBranchAddress(data.BranchAddress);
        setBranchPostcode(data.BranchPostcode);
        setBranchOrgUnitCode("" + data.BranchOrgUnitCode);
        setBranchValid(true);
      } else {
        resetBranchData();
        validationErrors.push(Constants.BRANCH_ID_NOT_FOUND_ERROR);
      }
    } else {
      toast.error(BRANCH_FINDER_ERRORS[`ERROR_${branchError}`] ?? branchError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchData, status, branchError]);

  const removeValidationErrors = (errors: string[]) => {
    errors.forEach((error) => {
      if (validationErrors.includes(error)) {
        validationErrors.splice(validationErrors.indexOf(error), 1);
      }
    });
    setValidationErrors(validationErrors);
  };

  const resetBranchData = () => {
    setBranchName("");
    setBranchAddress("");
    setBranchPostcode("");
    setBranchOrgUnitCode("");
  };

  const confirm = (confirmation: string) => {
    toggle();
    if (confirmation === ModelConfirmation.Yes) {
      setIsDirty(false);
      navigate(isNewBackend ? `/manager${USE_NEW_BACKEND}` : "/");
    }
  };

  useEffect(() => {
    if (!isShowing && isDirty) {
      window.history.pushState(null, null, window.location.pathname + "#/device");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowing]);

  const onBackButtonEvent = (event: PopStateEvent) => {
    event.preventDefault();
    if (isDirty) {
      toggle();
    } else {
      navigate(isNewBackend ? `/manager${USE_NEW_BACKEND}` : "/");
    }
  };

  useEffect(() => {
    if (location.hash.includes("#/device")) {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDirty) {
      window.history.pushState(null, null, window.location.pathname + "#/device");
      window.addEventListener("popstate", onBackButtonEvent);
      return () => {
        window.removeEventListener("popstate", onBackButtonEvent);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  const searchBranch = async () => {
    branchData || branchError ? branchRefresh() : getBranchData("apiconsumerBranchDetailsV01Get");
    await branchData;
  };

  useDidMountEffect(() => {
    //Do not set dirty, if data is filled from edit api
    if (pageType === "EDIT" && !editDataFetched.current) {
      return;
    }
    setIsDirty(true);
  }, [
    branchAddress,
    branchId,
    branchName,
    branchOrgUnitCodeVersion,
    branchOrgUnitCode,
    branchPostcode,
    deviceId,
    terminalName,
    nodeId,
    type,
    notes,
  ]);

  const terminalData: TerminalAddDataProps = {
    modalStatus: pageType,
    deviceId: deviceId,
    terminalName: terminalName,
    notes: notes,
    nodeId: nodeId,
    branchId: branchId,
    branchName: branchName,
    branchAddress: branchAddress,
    branchPostcode: branchPostcode,
    branchOrgUnitCode: branchOrgUnitCode,
    isShowing: isShowing,
    type: type,
    toggle: toggle,
    cancel: cancel,
    confirm: confirm,
    searchBranch: searchBranch,
  };

  return {
    onBranchIdChange,
    onDeviceIdChange,
    onTerminalNameChange,
    onNotesChange,
    onTypeChange,
    onNodeIdChange,
    setBranchId,
    setBranchName,
    setBranchAddress,
    setBranchPostcode,
    setBranchOrgUnitCode,
    handleAddDevice,
    handleEditDevice,
    terminalData,
    validationErrors,
  };
};
