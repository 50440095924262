import React, { FC, useEffect } from "react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";

const DEFAULT_REDIRECT: string = "/";

const Login: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let returnUrl = location.search
    .substring(1)
    .split("&")
    .find((s) => s.startsWith("return="));

  useEffect(() => {
    return onAuthUIStateChange((newAuthState: AuthState) => {
      if (newAuthState === AuthState.SignedIn) {
        navigate(returnUrl as string, { replace: true });
      }
    });
  }, [navigate, returnUrl]);

  returnUrl = returnUrl ? returnUrl.split("=")[1] : DEFAULT_REDIRECT;

  return (
    <Authenticator loginMechanisms={["email"]} socialProviders={[]}>
      {({ signOut, user }) => <Navigate replace to="/manager" />}
    </Authenticator>
  );
};

export default Login;
