import { FC, useContext, useLayoutEffect } from "react";
import Page from "../components/layout/Page";
import UserTableComp from "../components/UserTableComp";
import { useUsers } from "../lib/hooks/useUsers";
import { AppContext } from "../context/store";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isV2Api } from "../lib/helper";
import { USE_NEW_BACKEND, USE_NEW_BACKEND_KEY } from "../constants";

const UserManagement: FC = (): JSX.Element => {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const { retrieveUsers, searchUser } = useUsers();
  const [searchParams] = useSearchParams();
  const isNewBackend = isV2Api(searchParams.get(USE_NEW_BACKEND_KEY));

  useLayoutEffect(() => {
    state.users = [];
    retrieveUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToAddEditUser = (username?: string) => {
    username
      ? navigate(isNewBackend ? `/user/${username}${USE_NEW_BACKEND}` : `/user/${username}`)
      : navigate(isNewBackend ? `/user/${USE_NEW_BACKEND}` : `/user/`);
  };

  return (
    <Page>
      <UserTableComp rows={state.users} searchCallback={searchUser} addEditUserCallback={navigateToAddEditUser} />
    </Page>
  );
};

export default UserManagement;
