const baseApiUrl = `https://api.spm-${process.env.REACT_APP_ENV}.com/device-management`;

const refDataApiUrl = `https://api.spm-${process.env.REACT_APP_ENV}.com/RefData`;

const apiConfig = {
  baseApi: baseApiUrl,
  refDataApi: refDataApiUrl,
};

export default apiConfig;
