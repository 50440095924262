import { FC } from "react";
import Page from "../components/layout/Page";
import UserAddModal from "../components/UserAddEditModal";
import { Constants } from "../constants";
import { useParams } from "react-router-dom";

const AddEditUser: FC = () => {
  const params = useParams();
  const modalHeader = params.username ? Constants.EDIT_USER : Constants.Add_USER;
  return (
    <Page>
      <div className="max-w-7xl mx-auto py-8 px-5 bg-white">
        <div className="flex px-4 py-3 sm:px-6 sm:flex">
          <h1 className="flex-grow ml-3 text-xl font-bold leading-9 text-gray-900">{modalHeader}</h1>
        </div>
        <UserAddModal />
      </div>
    </Page>
  );
};

export default AddEditUser;
