export const terminals = [
  {
    id: "629f37b9-9091-4038-806f-f2a8abe29a53",
    name: "Terminal 1",
    branch_id: "",
  },
  {
    id: "9bc7aa16-0f63-418c-aac7-d27c8a971273",
    name: "Terminal 2",
    branch_id: "3e53cf5c-69fe-42b4-9423-8f0730029a98",
  },
  {
    id: "5bc7aa16-0f63-418c-aac7-d27c8a971272",
    name: "Terminal 3",
    branch_id: "3e53cf5c-69fe-42b4-9423-8f0730029a98",
  },
  {
    id: "dff99132-bc2a-498e-b84b-0d33252105f0",
    name: "Terminal 4",
    branch_id: "dff99132-bc2a-498e-b84b-0d33252105f0",
  },
  {
    id: "7731df31-7653-4c45-bc1c-190dce2f994c",
    name: "Terminal 5",
    branch_id: "7731df31-7653-4c45-bc1c-190dce2f994c",
  },
  {
    id: "c9c08002-aac1-43c9-9974-58aaa57c6133",
    name: "Terminal 6",
    branch_id: "c9c08002-aac1-43c9-9974-58aaa57c6133",
  },
];

export const branches = [
  {
    branch_id: "hfejkwfe",
    branchName: "Crick",
    address: "86 Main Road, Crick, Northampton, Northamptonshire NN6 7TX",
  },
  {
    branch_id: "hfejkwfk",
    branchName: "Hillmorton",
    address: "59-61 High Street, Hillmorton, Rugby, Warwickshire CV21 4EG",
  },
  {
    branch_id: "abcdefg",
    branchName: "Barby",
    address: "5 Rugby Road, Barby, Rugby, Warwickshire CV23 8UA",
  },
  {
    branch_id: "1263bab5",
    branchName: "Abbots Farm",
    address: "237 Lower Hillmorton Road, Rugby, Warwickshire CV21 4AA",
  },
  {
    branch_id: "7263beb5",
    branchName: "Yelvertoft",
    address: "80 High Street, Yelvertoft, Northampton, Northamptonshire NN6 6LQ",
  },
  {
    branch_id: "7263beb76",
    branchName: "Clifton Road",
    address: "321-323 Clifton Road, Rugby, Warwickshire CV21 3QZ",
  },
  {
    branch_id: "3e53cf5x",
    branchName: "Newcastle upon Tyne",
    address: "36 Northumberland St, Newcastle upon Tyne NE1 7DE",
  },
  {
    branch_id: "3e53cf5d",
    branchName: "London",
    address: "19A Borough High St, London SE1 9SF",
  },
];
