import React, { FC, useEffect, useState } from "react";
import Select from "react-select";
import { UpdateDeviceOTPRequest } from "../../openapi/device-api/api";
import useApi from "../../lib/hooks/useApi";
import { getDeviceManagementApiClient as apiClient } from "../../lib/api-helper/apiHelper";
import { Terminal } from "../../lib/models";
import { DEVICE_TYPE_OPTIONS, USE_NEW_BACKEND_KEY } from "../../constants";
import { isV2Api } from "../../lib/helper";
import { useSearchParams } from "react-router-dom";
interface OtpProps {
  otp?: string;
  device: Terminal;
}

const Otp: FC<OtpProps> = ({ otp, device }): JSX.Element => {
  const [otpValue, setOtpValue] = useState<string | undefined>(undefined);
  const [visibleResponse, setVisibleResponse] = useState<JSX.Element>(undefined);

  const otpParams: UpdateDeviceOTPRequest = { id: device.id };
  const [searchParams] = useSearchParams();

  const [{ data: otpResponse, status: otpStatus, error: otpError, statusCode: otpStatusCode }, getOtp, refreshOtp] =
    useApi(apiClient, "", otpParams);

  const handleOtpRefresh = async () => {
    if (typeof otpResponse === "undefined" && typeof otpError === "undefined") {
      getOtp(isV2Api(searchParams.get(USE_NEW_BACKEND_KEY)) ? "v2DeviceOtpPut" : "deviceOtpPut");
    } else {
      refreshOtp();
    }
  };

  useEffect(() => {
    if (otp !== null) {
      setOtpValue(otp);
    }
  }, [otp]);

  useEffect(() => {
    if (otpResponse !== undefined) {
      setOtpValue(otpResponse?.otp);
      setVisibleResponse(<p className="text-green-400">OTP generated successfully.</p>);
    }
  }, [otpResponse]);

  useEffect(() => {
    if (otpStatus === "error") {
      setOtpValue("");
      setVisibleResponse(
        <p className="text-red-400">{`Couldn’t generate OTP at this time${
          otpStatusCode ? ` (Error ${otpStatusCode})` : ``
        }.`}</p>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpStatus, otpError]);

  return (
    <div>
      <div className="flex mt-24 py-2 px-16 sm:py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:pt-3">
        <dt className="text-sm font-medium text-gray-700">Device ID</dt>
        <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">
            <input
              disabled={true}
              value={device.id}
              type="text"
              name="deviceId"
              id="deviceId"
              className="edit-data-input sm:text-sm disabled"
            />
          </span>
        </dd>
      </div>

      <div className="flex mt-2 py-2 px-16 sm:py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:pt-3">
        <dt className="text-sm font-medium text-gray-700">Branch ID</dt>
        <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">
            <input
              value={device.branch_id}
              required
              type="text"
              name="branchId"
              id="branchId"
              disabled={true}
              className="edit-data-input sm:text-sm disabled"
            />
          </span>
        </dd>
      </div>

      <div className="flex mt-2 py-2 px-16 sm:py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:pt-3">
        <dt className="text-sm font-medium text-gray-700">Branch Name</dt>
        <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">
            <input
              value={device.branch_name}
              required
              type="text"
              name="branchName"
              id="branchName"
              disabled={true}
              className="edit-data-input sm:text-sm disabled"
            />
          </span>
        </dd>
      </div>

      <div className="flex mt-2 py-2 px-16 sm:py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:pt-3">
        <dt className="text-sm font-medium text-gray-700">Device Type</dt>
        <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">
            <Select
              value={DEVICE_TYPE_OPTIONS.find((item) => item.value === device.type)}
              isSearchable
              isClearable
              maxMenuHeight={200}
              isDisabled={true}
              className="flex-1 capitalize disabled"
            />
          </span>
        </dd>
      </div>

      <div className="flex mt-2 py-2 px-16 sm:py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:pt-3">
        <dt className="text-sm font-medium text-gray-700">OTP</dt>

        <dd className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <div className="flex-grow">
            <label htmlFor="otp" className="sr-only">
              Refresh OTP
            </label>

            <div className="flex rounded-md">
              <input
                readOnly
                disabled
                value={otpValue}
                name="otp"
                id="otp"
                className="edit-data-input sm:text-sm"
                placeholder="Get new OTP"
              />
              <button
                onClick={() => handleOtpRefresh()}
                className="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                Generate
              </button>
            </div>
          </div>
          <div>{visibleResponse}</div>
        </dd>
      </div>
      <div className="flex mt-2 py-2 px-16 flex-col sm:py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:pt-3">
        <text className="text-sm font-bold text-red-500 sm:col-span-3">
          {"WARNING: "}
          <text className="text-sm italic font-medium text-gray-700 py-4 ">
            Generating an OTP for this device will automatically prevent the device from communicating with the Post
            Office application(s) installed on this device as the device will be unauthorized until the OTP that is
            generated here has been entered on to the device.
          </text>
        </text>
      </div>
    </div>
  );
};

export default Otp;
