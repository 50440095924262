import { Terminal as ITerminal } from "../../lib/models";
import moment from "moment";

export const useExportDevices = () => {
  const exportedDevices = (devices: ITerminal[]): ITerminal[] => {
    const results = JSON.parse(JSON.stringify(devices));
    return results.map((device) => {
      const NOT_AVAILABLE = "N/A";
      const branchAddress = device.branch_address.split(",");
      const branch_address1 = branchAddress[0] ? branchAddress[0] : "";
      const branch_address2 = branchAddress[1] ? branchAddress[1] : "";
      const branch_address3 = branchAddress[2] ? branchAddress[2] : "";
      const branch_address4 = branchAddress[3] ? branchAddress[3] : "";
      const branch_address5 = branchAddress[4] ? branchAddress[4] : "";

      const modified_at =
        device.modified_at && device.modified_at !== "0001-01-01T00:00:00Z"
          ? moment.utc(device.modified_at).local().format("MMM DD, YYYY HH:mm:ss")
          : NOT_AVAILABLE;

      const created_at =
        device.created_at && device.created_at !== "0001-01-01T00:00:00Z"
          ? moment.utc(device.created_at).local().format("MMM DD, YYYY HH:mm:ss")
          : NOT_AVAILABLE;
      const modified_by = device.modified_by !== "" ? device.modified_by : NOT_AVAILABLE;

      return {
        ...device,
        modified_at,
        created_at,
        modified_by,
        branch_address1,
        branch_address2,
        branch_address3,
        branch_address4,
        branch_address5,
      };
    });
  };

  const deviceHeaders = [
    { label: "Device ID", key: "id" },
    { label: "Device Name", key: "name" },
    { label: "Device Type", key: "type" },
    { label: "Node ID", key: "node_id" },
    { label: "Location FAD Code (Branch ID)", key: "branch_id" },
    { label: "Branch Name", key: "branch_name" },
    { label: "AddressLine1", key: "branch_address1" },
    { label: "AddressLine2", key: "branch_address2" },
    { label: "AddressLine3", key: "branch_address3" },
    { label: "AddressLine4", key: "branch_address4" },
    { label: "AddressLine5", key: "branch_address5" },
    { label: "PostCode", key: "branch_postcode" },
    { label: "Notes", key: "notes" },
    { label: "DateCreated", key: "created_at" },
    { label: "Last modified on", key: "modified_at" },
    { label: "Last modified by", key: "modified_by" },
  ];
  return {
    deviceHeaders,
    exportedDevices,
  };
};
