/* tslint:disable */
/* eslint-disable */
/**
 * Device Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BranchDetailResponse
 */
export interface BranchDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof BranchDetailResponse
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof BranchDetailResponse
     */
    'Version': string;
    /**
     * 
     * @type {string}
     * @memberof BranchDetailResponse
     */
    'Item': string;
    /**
     * 
     * @type {string}
     * @memberof BranchDetailResponse
     */
    'Extracted': string;
    /**
     * 
     * @type {string}
     * @memberof BranchDetailResponse
     */
    'ValidFrom': string;
    /**
     * 
     * @type {Array<BranchDetailResponseData>}
     * @memberof BranchDetailResponse
     */
    'Data': Array<BranchDetailResponseData>;
}
/**
 * 
 * @export
 * @interface BranchDetailResponseData
 */
export interface BranchDetailResponseData {
    /**
     * 
     * @type {string}
     * @memberof BranchDetailResponseData
     */
    'Branch_ID': string;
    /**
     * 
     * @type {string}
     * @memberof BranchDetailResponseData
     */
    'Branch_Name': string;
    /**
     * 
     * @type {string}
     * @memberof BranchDetailResponseData
     */
    'Branch_Address': string;
    /**
     * 
     * @type {string}
     * @memberof BranchDetailResponseData
     */
    'Branch_Postcode': string;
    /**
     * 
     * @type {number}
     * @memberof BranchDetailResponseData
     */
    'Branch_Org_Unit_Code': number;
    /**
     * 
     * @type {number}
     * @memberof BranchDetailResponseData
     */
    'Location_Type_Code': number;
    /**
     * 
     * @type {string}
     * @memberof BranchDetailResponseData
     */
    'Location_Type_Name': string;
    /**
     * 
     * @type {string}
     * @memberof BranchDetailResponseData
     */
    'Trading_Open_Status': string;
    /**
     * 
     * @type {string}
     * @memberof BranchDetailResponseData
     */
    'Trading_Sub_Status_Code': string;
    /**
     * 
     * @type {string}
     * @memberof BranchDetailResponseData
     */
    'Trading_Sub_Status_Name': string;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {number}
     * @memberof ErrorResponse
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'message': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves branch details
         * @param {string} apiconsumer The consumer of this api.
         * @param {string} fadCode Comprises of 6 digits plus a check digit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiconsumerBranchDetailsV01Get: async (apiconsumer: string, fadCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiconsumer' is not null or undefined
            assertParamExists('apiconsumerBranchDetailsV01Get', 'apiconsumer', apiconsumer)
            // verify required parameter 'fadCode' is not null or undefined
            assertParamExists('apiconsumerBranchDetailsV01Get', 'fadCode', fadCode)
            const localVarPath = `/{apiconsumer}/BranchDetails/v0_1`
                .replace(`{${"apiconsumer"}}`, encodeURIComponent(String(apiconsumer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiGateway required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fadCode !== undefined) {
                localVarQueryParameter['fadCode'] = fadCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves branch details
         * @param {string} apiconsumer The consumer of this api.
         * @param {string} fadCode Comprises of 6 digits plus a check digit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiconsumerBranchDetailsV01Get(apiconsumer: string, fadCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiconsumerBranchDetailsV01Get(apiconsumer, fadCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves branch details
         * @param {string} apiconsumer The consumer of this api.
         * @param {string} fadCode Comprises of 6 digits plus a check digit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiconsumerBranchDetailsV01Get(apiconsumer: string, fadCode: string, options?: any): AxiosPromise<BranchDetailResponse> {
            return localVarFp.apiconsumerBranchDetailsV01Get(apiconsumer, fadCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves branch details
     * @param {string} apiconsumer The consumer of this api.
     * @param {string} fadCode Comprises of 6 digits plus a check digit.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiconsumerBranchDetailsV01Get(apiconsumer: string, fadCode: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiconsumerBranchDetailsV01Get(apiconsumer, fadCode, options).then((request) => request(this.axios, this.basePath));
    }
}


