import React, { FC, useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Transition } from "@headlessui/react";
import { Check, X } from "heroicons-react";
import { useLockBodyScroll } from "../lib/hooks/useLockBodyScroll";
import FocusLock from "react-focus-lock";

export interface ModalProps {
  isShowing: boolean;
  hide: () => void;
  modalHeader?: string;
  confirmation?: (confirmation: string) => void;
  modalBody: JSX.Element;
  closeable?: boolean;
  fullPage?: boolean;
}

export enum ModelConfirmation {
  Yes = "YES",
  No = "NO",
}

const Modal: FC<ModalProps> = ({
  isShowing,
  hide,
  modalHeader,
  confirmation,
  modalBody,
  closeable,
  fullPage,
}): JSX.Element => {
  // Creates Reference for Modal to enable background click to close
  const node = useRef<HTMLDivElement>(null);

  // Handle esc key and click events in the modal
  const onModalClick = useCallback(
    (event) => {
      if (!node?.current?.contains(event.target) && !fullPage) hide();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hide]
  );
  const onEscKeyDown = useCallback(
    (event) => {
      if (event.key === "Escape" && !fullPage) hide();
    },
     // eslint-disable-next-line react-hooks/exhaustive-deps
    [hide]
  );

  // Disable background scrolling when modal is mounted
  useLockBodyScroll();

  // Modal Event Listeners
  useEffect(() => {
    isShowing && document.addEventListener("mousedown", onModalClick);
    document.addEventListener("keydown", onEscKeyDown, false);

    return () => {
      document.removeEventListener("mousedown", onModalClick);
      document.removeEventListener("keydown", onEscKeyDown, false);
    };
  }, [onModalClick, onEscKeyDown, isShowing]);

  const modal = (
    <>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" />
          </div>
          <span
            data-testid="modalOutside"
            className="hidden w-full min-h-full sm:inline-block sm:align-middle"
            aria-hidden="true"
          >
            ​
          </span>
          <div
            ref={node}
            className={`${
              fullPage ? `max-w-6xl h-[calc(100vh-140px)]` : `inline-block max-w-3xl`
            } m-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl w-1/1 sm:my-8 sm:align-middle`}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <FocusLock>
              {modalHeader && (
                <div className="flex px-4 py-3 bg-gray-50 sm:px-6 sm:flex">
                  <h1 className="flex-grow ml-3 text-xl font-bold leading-9 text-gray-900">{modalHeader}</h1>
                  {closeable && (
                    <button
                      onClick={hide}
                      type="button"
                      data-testid="modalCloseButton"
                      className="flex-shrink btn btn-clear"
                    >
                      <X className="inline" size={18} />
                    </button>
                  )}
                </div>
              )}
              <div>
                <div
                  data-testid="modalBody"
                  className={`${fullPage && `h-[calc(100vh-220px)] overflow-auto`} bg-white m-4 `}
                >
                  {modalBody}
                </div>
              </div>

              {confirmation && (
                <div className="flex flex-row justify-center flex-grow px-4 py-3 bg-gray-50 sm:px-6">
                  <button
                    onClick={() => confirmation(ModelConfirmation.Yes)}
                    type="button"
                    className="ml-4 btn btn-success"
                  >
                    <Check className="inline" size={18} /> Yes
                  </button>

                  <button
                    onClick={() => confirmation(ModelConfirmation.No)}
                    type="button"
                    className="ml-4 btn btn-cancel"
                  >
                    <X className="inline" size={18} /> No
                  </button>
                </div>
              )}
            </FocusLock>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      {createPortal(
        <Transition
          show={isShowing}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {modal}
        </Transition>,
        document.body
      )}
    </>
  );
};

export default Modal;
